import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import artworksJson from '../artworks.json';
import StackGrid from "react-stack-grid";
import { Link } from 'react-router-dom';
import { SoftBackgroundImage } from '../components/SoftImage';
import { preloadImages } from '../components/ImageCache';

class Album extends Component {

    allAlbums = artworksJson;
    photoRefs = [];
    columnWidth = isMobile ? 250 : 400;

    photoToScrollTo = -1;

    constructor(props) {
        super(props);
        this.artworkAlbumName = props.match.params.albumName;

        this.artworkAlbum = this.allAlbums.find(album => album.name == this.artworkAlbumName);
        this.artworkAlbumUrl = "/artwork/" + this.artworkAlbumName;

        // encodeURI is needed because of the spaces in the names of images
        this.albumPhotos = this.artworkAlbum.files.map(artwork => encodeURI(require("../images/art/" + this.artworkAlbumName + "/" + artwork.path)));
        this.photoUrls = this.albumPhotos.map((_, i) => this.artworkAlbumUrl + "/" + i);
        this.photoUrls.forEach((_, i) => { this.photoRefs[i] = React.createRef(); });
    }

    componentDidMount() {
        let optionalPhotoParam = this.props.match.params.photo;
        if (optionalPhotoParam != null){
            // Open the gallery from a specific location
            this.props.backgroundGallery.current.initialiseFromPosition(this.albumPhotos, this.photoUrls, 
                this.artworkAlbumUrl,  parseInt(optionalPhotoParam), false);
        }else{
            // Prepare the gallery in the background, while ensuring we first load the thumbnail images
            preloadImages(this.albumPhotos, () => {});
            this.props.backgroundGallery.current.initialiseEmpty(false);

            // Scroll to the image that the user just finished viewing
            let hashPhotoIndex = this.props.location.hash.substr(1);
            if (hashPhotoIndex !== ""){
                let viewedPhotoIndex = parseInt(hashPhotoIndex);
                this.photoToScrollTo = viewedPhotoIndex;
                // Intentionally avoiding scrolling here, as the required ref gets filled in later on.
            }else{
                window.scrollTo(0, 0);
            }
        }

        this.props.mainMenu.current.setTheme(false);
    }

    getAlbumIndex(albumName){
        return this.allAlbums.findIndex(element => { return element.path === albumName });
    }

    textToHtml(text){
        return {__html: text};
    }

    scrollToViewedPhoto(element, i){
        if (i !== this.photoToScrollTo){
            return;
        }

        // Commented out: auto-scroling doesn't feel very natural at the moment.
        // if (this.photoToScrollTo < 0 || this.photoToScrollTo > this.photoRefs.length){
        //     console.warn("There is no such artwork: " + this.photoToScrollTo);
        // }else{
        //     // This seems to be needed due to the Stack Grid library's animation. 
        //     setTimeout(() => {
        //         element.scrollIntoView({ behavior: 'smooth' });
        //         this.photoToScrollTo = -1;
        //     }, 150);
        // }
    }

    makePhoto(i) {
        let height = (this.columnWidth / this.artworkAlbum.files[i].width) * this.artworkAlbum.files[i].height;
        return (
            <Link 
                to={ this.photoUrls[i] } 
                key={this.artworkAlbumName + "_" + i} 
                ref={ element => this.scrollToViewedPhoto(element, i) } 
                className="img-hover-zoom">

                <SoftBackgroundImage 
                    id={ "photo_" + i } 
                    src={ this.albumPhotos[i] }
                    almostFullOpacity={true}
                    width={ this.columnWidth } 
                    height={ height } 
                    className="img-to-zoom"/>

                <div className="overlay-title" >{ this.artworkAlbum.files[i].title }</div>
            </Link>

        );
    }

    makeGrid(){
        return (
            this.artworkAlbum.files.length == 0 ? null :
                <StackGrid className="disable-select" columnWidth={ this.columnWidth } gutterWidth={ 20 } gutterHeight={ 20 } duration={ 0 }>
                    { this.artworkAlbum.files.map( (artwork, i) => this.makePhoto(i)) }
                </StackGrid>
        )
    }

    render() {
        return (
            <div>

                <div className="content">
                    <div className="album-title">

                        <h3>{ this.artworkAlbum.name }</h3>
                        <br/>
                        <p className="album-description">{ this.artworkAlbum.description }</p>

                    </div>

                    { this.makeGrid() }

                </div>
            </div>
        );
    }
}

export default Album

import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainMenu from './marton/menus/MainMenu'
import About from './marton/views/About'
import AllArtwork from './marton/views/AllArtwork'
import Album from './marton/views/Album'
import BackgroundGallery from './marton/components/BackgroundGallery'
import { LastLocationProvider } from 'react-router-last-location';

class App extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            containerVisible: true
        }

        this.backgroundGalleryRef = React.createRef();
        this.mainMenu = React.createRef();
    }

    toggleContainerVisibility(visible){
        if (this.state.containerVisible !== visible){
            this.setState({containerVisible: visible});
        }
    }

    render() {
        let containerClassName = this.state.containerVisible ? "visible" : "invisible";

        const SiteContainer = () => (
            <div className={"height-100 " + containerClassName}>
                <div className="container">
                    <MainMenu ref={ this.mainMenu }/>
                
                    <Route render={({ location }) => {

                        return (
                            <Switch location={location}>
                                <Route 
                                    exact path={["/", "/artwork"]}
                                    component={(props) => (
                                        <AllArtwork {...props} backgroundGallery={this.backgroundGalleryRef} mainMenu={this.mainMenu} />
                                    )} />

                                <Route 
                                    path="/about"
                                    render={(props) => (
                                        <About {...props} backgroundGallery={this.backgroundGalleryRef} mainMenu={this.mainMenu} />
                                    )} />

                                <Route 
                                    path="/artwork/:albumName/:photo?" 
                                    component={(props) => (
                                        <Album {...props} 
                                            backgroundGallery={this.backgroundGalleryRef} 
                                            mainMenu={this.mainMenu} 
                                            toggleContainerVisibility={this.toggleContainerVisibility.bind(this)} />
                                    )} />

                            </Switch>
                        )
                    }}/>
                </div>

                <div className="copyright disable-select">
                    <p>Copyright 2020 - Boglárka Széles</p>
                </div>

            </div>
        );
        
        return (
            <BrowserRouter>

                <LastLocationProvider>

                    <div className="app">

                        <BackgroundGallery
                            toggleContainerVisibility={ this.toggleContainerVisibility.bind(this) }
                            ref={ this.backgroundGalleryRef }>
                        </BackgroundGallery>

                        <Switch>
                            <Route component={SiteContainer}/>
                        </Switch>

                    </div>

                </LastLocationProvider>

            </BrowserRouter>
        )
    }
}
  
export default App;

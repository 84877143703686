import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { allImagesCached, preloadImages } from '../components/ImageCache';
import { withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { Swipeable } from '../components/Swipeable';

class BackgroundGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switch: true,
            loading: false
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyPressed, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyPressed, false);
    }

    interval = null;
    position = 0;
    previousPosition = 0;
    previousImage = null;
    images = [];

    urls = [];
    albumUrl = "";

    autoPlay = false;
    lowOpacity = false;
    controlPanel = false;
    darkBackground = false;
    permanentPlayer = false;
    scaledBackground = false;

    changeSettings(
        images,
        urls,
        albumUrl,
        autoPlay = false,
        lowOpacity = false,
        controlPanel = false,
        darkBackground = false,
        permanentPlayer = false,
        scaledBackground = false)
    {
        this.images = images;
        this.urls = urls;
        this.albumUrl = albumUrl;
        this.autoPlay = autoPlay;
        this.lowOpacity = lowOpacity;
        this.controlPanel = controlPanel;
        this.darkBackground = darkBackground;
        this.permanentPlayer = permanentPlayer;
        this.scaledBackground = scaledBackground;
    }

    resetPreviousImages(){
        this.previousPosition = 0;
        this.previousImage = null;
    }

    rememberPreviousImage(newImagesExpected){
        if (newImagesExpected){
            // This means there were images previously available.
            // By remembering the last image on display, we can ensure a smooth transition to the next.
            this.previousPosition = this.position;
            this.previousImage = this.images[this.previousPosition];
        }
    }

    initialiseEmpty(dark){
        this.changeSettings([], [], "", false, false, false, dark, false, false);
        this.resetPreviousImages();

        this.props.toggleContainerVisibility(true);
        this.setState({});
    }

    // Sets the background to look plain. Ideal when e.g. the new state takes time to load.
    // This can display an actual loading icon in the future - if needed.
    setIntermediateState(darkBackground = false){
        this.changeSettings([], [], "", false, false, false, darkBackground, false, false);
        this.setState({loading: true});
    }

    initialise(
        images,
        urls,
        albumUrl,
        autoPlay = false,
        lowOpacity = false,
        controlPanel = false,
        darkBackground = false,
        permanentPlayer = true,
        scaledBackground = false)
    {
        this.props.toggleContainerVisibility(true);

        let showGallery = () => {
            this.rememberPreviousImage(images.length > 0);
            this.position = 0;
            this.changeSettings(images, urls, albumUrl, autoPlay, lowOpacity, controlPanel, darkBackground, permanentPlayer, scaledBackground);
            this.move();
            if (this.autoPlay){
                this.start();
            }else{
                this.pause();
            }
        }

        if (allImagesCached(images)){
            showGallery();
        }else{
            this.setIntermediateState(darkBackground);
            preloadImages(images, showGallery);
        }
    }

    initialiseFromPosition(images, urls, albumUrl, position, darkBackground){
        this.pause();

        this.props.toggleContainerVisibility(false);

        let showGallery = () => {
            this.rememberPreviousImage(images.length > 0);
            this.changeSettings(images, urls, albumUrl, false, false, true, darkBackground, false, true);
            this.position = this.validPosition(position);
            this.move();
        }

        if (allImagesCached(images)){
            showGallery();
        }else{
            this.setIntermediateState(true);
            preloadImages(images, showGallery);
        }
    }

    handleKeyPressed = (event) => {
        switch(event.keyCode){
            case 27:
                if (this.albumUrl){
                    this.exitFullScreen();
                }
                break;
            case 37:
                if (this.permanentPlayer){
                    this.manualStepBackward();
                }else{
                    this.props.history.push(this.getPreviousLink());
                }

                break;
            case 39:
                if (this.permanentPlayer){
                    this.manualStepForward();
                }else{
                    this.props.history.push(this.getNextLink());
                }

                break;
            default:
                break;
        }

        return false;
    }

    onSwipedLeft = (args) => {
        this.manualStepBackward();
    }

    onSwipedRight = (args) => {
        this.manualStepForward();
    }

    // Starts auto-playing the slideshow
    start(){
        if (this.interval != null){
            this.pause();
        };

        this.interval = setInterval(() => {
            this.stepForward();
        }, 5000);
    }

    // Stops the auto-play of the slideshow
    pause(){
        clearInterval(this.interval);
    }

    validPosition(position){
        return (position % this.images.length + this.images.length) % this.images.length;
    }

    manualStepForward(){
        this.pause();
        this.stepForward();
    }

    stepForward(){
        this.rememberPreviousImage(true);
        this.position = this.validPosition(this.position + 1);
        this.move();
    }

    manualStepBackward(){
        this.pause();
        this.stepBackward();
    }

    stepBackward(){
        this.rememberPreviousImage(true);
        this.position = this.validPosition(this.position - 1);
        this.move();
    }

    // Triggers a state refresh, i.e. re-render
    move(){
        this.setState({switch: !this.state.switch, loading: false});
    }

    makeBackground(){
        if (this.images == null){
            return null;
        }

        let bg1ClassName = "fullOpacity";
        let bg2ClassName = "fullOpacity";
        let loadingBackgroundClassName = this.state.loading ? "fullOpacity" : "zeroOpacity";
        let darkBackgroundClassName = this.darkBackground ? "fullOpacity" : "zeroOpacity";
        let topImage = null;
        let bottomImage = null;
        if (this.state.switch){
            bg1ClassName = "fullOpacity";
            topImage = this.images[this.position];
            bottomImage = this.previousImage;
            if (this.lowOpacity){
                bg1ClassName += " lowerOpacity";

                // It's important to make the other layer invisible so that it doesn't interfere
                bg2ClassName = "zeroOpacity";
            }
        }else{
            bg1ClassName = "zeroOpacity";
            topImage = this.previousImage;
            bottomImage = this.images[this.position];
            if (this.lowOpacity){
                bg2ClassName += " lowerOpacity";
            }
        }

        if (this.images.length === 0){
            // Allows layers to fade in once the images are ready
            bg1ClassName = "zeroOpacity";
            bg2ClassName = "zeroOpacity";
        }

        let bgSizeClassName = this.scaledBackground ? "bg-scale-screen" : "bg-fill-screen";

        return(
            <div>
                {/* Bit of a madness: we need to properly escape the image URLs, as they may contain "(" and ")" */}
                <div
                    className={bg1ClassName + " background-pic " + bgSizeClassName}
                    style={topImage ? { backgroundImage: `url('${topImage}')`, zIndex: -999 } : {}}>
                </div>
                <div
                    className={bg2ClassName + " background-pic " + bgSizeClassName}
                    style={bottomImage ? { backgroundImage: `url('${bottomImage}')`, zIndex: -1000 } : {}}>
                </div>
                <div
                    className={loadingBackgroundClassName + " loading-background"}
                    style={{ zIndex: -1001 }}>
                </div>
                <div
                    className={darkBackgroundClassName + " dark-background"}
                    style={{ zIndex: -1002 }}>
                </div>

            </div>
        );
    }

    albumUrlWithPhotoHash(){
        return this.albumUrl + "#" + this.position;
    }

    exitFullScreen(){
        let photoSpecificAlbumUrl = this.albumUrlWithPhotoHash();
        this.props.history.push(photoSpecificAlbumUrl);
    }

    getPreviousLink(){
        let previousPosition = this.validPosition(this.position - 1);
        return this.urls.length === 0 ? "" : this.urls[previousPosition];
    }

    getNextLink(){
        let nextPosition = this.validPosition(this.position + 1);
        return this.urls.length === 0 ? "" : this.urls[nextPosition];
    }

    makeControlPanel(){
        if (this.controlPanel){
            if (this.permanentPlayer){
                return (
                    <Swipeable onSwipedLeft={ this.onSwipedLeft.bind(this) } onSwipedRight={ this.onSwipedRight.bind(this) } className="c-container">
                        <div className="c-move c-left" onClick={this.manualStepBackward.bind(this)}></div>
                        <div className="c-move c-right" onClick={this.manualStepForward.bind(this)}></div>
                    </Swipeable>
                );
            }else{
                return (
                    <Swipeable onSwipedLeft={ this.onSwipedLeft.bind(this) } onSwipedRight={ this.onSwipedRight.bind(this) } className="c-container">
                        <Link to={ this.getPreviousLink() } className="c-move c-left"/>
                        <Link to={ this.getNextLink() } className="c-move c-right"/>
                        <div onClick={ this.exitFullScreen.bind(this) } className="c-exit"></div>
                    </Swipeable>
                );
            }
        }

        return null;
    }

    render() {

        let controlPanel = this.makeControlPanel();
        let background = this.makeBackground();

        return (
            <div>

                <div className="background-container">

                    { background }

                </div>

                { controlPanel }

            </div>
        )
    }
}

const withRouterAndRef = Wrapped => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
      <Wrapped ref={forwardRef} {...otherProps} />
    ))
    const WithRouterAndRef = React.forwardRef((props, ref) => {
        const lastLocation = useLastLocation();
        return <WithRouter {...props} forwardRef={ref} lastLocation={lastLocation}/>;
    })
    const name = Wrapped.displayName || Wrapped.name
    WithRouterAndRef.displayName = `withRouterAndRef(${name})`
    return WithRouterAndRef
}

export default withRouterAndRef(BackgroundGallery)

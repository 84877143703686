import React, { Component } from 'react';
import { SoftImage, SoftBackgroundImage } from '../components/SoftImage';
import { isMobile } from "react-device-detect";

class About extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.backgroundGallery.current.initialiseEmpty(false);
    }

    render() {
        let profilePicture = require("../images/src/profile.jpg");

        let flickrLogo = require("../images/src/flickr.png");
        let instagramLogo = require("../images/src/instagram.png");
        let logoWidth = "24px";
        let logoHeight = "24px";
        let www = isMobile ? "" : "www.";
                
        return (

            <div className="content">
                
                <div className="profile">
                    <div className="profile-half z99 profile-img-container">
                        <div className="profile-img">
                            <SoftImage className="z99" src={ profilePicture } />
                        </div>
                    </div>

                    <div className="profile-half z100 profile-description-container">
                        <div className="profile-description">
                            <h1>BOGLÁRKA SZÉLES</h1>
                            <p>Aspiring to be a professional artist. I desire to continue my higher education in Fine Art. My main inspiration comes from nature. Besides that, the Cubists, the Expressionists and modern artists have all immensely influenced my work. I hope the visitors of this website will enjoy looking at my artworks.</p>

                            <div className="contact_info">
                                
                                <a href="https://www.flickr.com/photos/191228122@N03/albums" target="_blank" rel="noopener noreferrer">
                                    <span className="title">
                                        <SoftImage src={ flickrLogo } width= { logoWidth } height={ logoHeight } alt="Flickr"></SoftImage>
                                    </span>
                                    <span className="value">Flickr: LLamaPaints</span>
                                </a>

                                <a href="https://www.instagram.com/painterllama/" target="_blank" rel="noopener noreferrer">
                                    <span className="title">
                                        <SoftImage src={ instagramLogo } width= { logoWidth } height={ logoHeight } alt="Instagram"></SoftImage>
                                    </span>
                                    <span className="value" >{www}instagram.com/painterllama</span>
                                </a>

                            </div>

                        </div>

                    </div>

                </div>
                

            </div>

        );
    }
}

export default About

import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';

class MainMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            darkLogo: false
        }
    }

    // Intentionally leaving this minimalistic for the time being
    setTheme(dark){
        this.setState({darkLogo: dark});
    }

    getMenuIconElement(){
        return document.getElementById("menu-icon");
    }

    render() {
        return (
            <div className="disable-select">

                <div className="menu">
                    <div className={ "logo " + (this.state.darkLogo ? "dark-logo" : "") }>
                        <Link to="/artwork">Boglárka Széles</Link>
                    </div>

                    <div className='dashboard'>

                        <div className="menu-item">
                            <NavLink 
                                strict to="/artwork" 
                                isActive={(match, location) => {
                                    return location.pathname == "/" || location.pathname.startsWith("/artwork")
                                }} 
                                activeClassName="active" className="dashboard_tabs">artwork</NavLink>
                        </div>
                        <div className="menu-item">
                            <NavLink strict to="/about" activeClassName="active" className="dashboard_tabs">about</NavLink>
                        </div>
                        
                    </div>

                </div>

            </div>
        )
    }
}

export default MainMenu

import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';
import StackGrid from "react-stack-grid";
import artworksJson from '../artworks.json';
import { SoftImage } from '../components/SoftImage';

class AllArtwork extends Component {

    artworks = artworksJson;
    artworkRefs = [];
    columnWidth = isMobile ? 250 : 400;
    gutter = 20;

    photoToScrollTo = -1;

    constructor(props) {
        super(props);
        this.artworkAlbums = this.artworks.map(artworkAlbum => encodeURI(require("../images/art/" + artworkAlbum.name + "/" + artworkAlbum.files[0].path)));
    }

    componentDidMount() {
    }
    
    makeArtworkElement(album, i){
        let height = this.columnWidth;
        
        return (
            <Link 
                to={ "/artwork/" + album.name  } 
                key={ i } 
                className="img-hover-zoom"
                style={{ width : this.columnWidth , height : height}}>


                <SoftImage src={ this.artworkAlbums[i] } width={ this.columnWidth } height={ height } className="img-to-zoom" />
                <div className="overlay-title" >{ this.artworks[i].name }</div>
                
            </Link>
        )
    }

    makeGrid(){
        return (
            this.artworks.length == 0 ? null :

                <StackGrid className="disable-select" columnWidth={ this.columnWidth } gutterWidth={ 20 } gutterHeight={ 20 } duration={ 0 }>
                    { this.artworks.map( (artwork, i) => this.makeArtworkElement(artwork, i)) }
                </StackGrid>
        )
    }

    render() {
        return( 
            <div className="main_container height-hack">
                { this.makeGrid() }
            </div> 
        )
    }
}

export default AllArtwork
